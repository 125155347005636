import React, { memo } from 'react'
import styles from './styles.module.scss'

export const Rating = memo(({ percent, height, width }) => (
  <div className={styles.rating} style={{ height, width }}>
    <span
      className={styles.inactive}
      style={{ backgroundSize: height, height, width }}
    ></span>
    <span
      className={styles.activeContainer}
      style={{ height, width: percent + '%' }}
    >
      <span
        className={styles.active}
        style={{ backgroundSize: height, height, width }}
      ></span>
    </span>
  </div>
))

Rating.displayName = 'Rating'
Rating.defaultProps = {
  percent: 0,
  height: 20,
  width: 100,
}
