import { useMemo, useState, useCallback, useRef } from 'react'
import orderBy from 'lodash/orderBy'
import { ROWS_ON_PAGE } from '../constants'
import { getValuesBuilder } from '../helpers'
import { calculatePnL } from 'src/utils/number/pnl'
import { isNumber } from 'src/utils/number/number'
import { combinedAnalytics } from 'src/utils/common'

export const useData = ({
  data,
  pnl,
  top,
  sort,
  sortChanged,
  viewAll,
  page,
}) => {
  const rowsOnPage = ROWS_ON_PAGE
  const currentPage = useMemo(
    () => (top === 'all' ? page : page > 0 ? 1 : 0),
    [top, page]
  )

  const currentSort = useMemo(
    () =>
      top === 'all'
        ? sort
        : sortChanged
        ? sort
        : {
            field: 'change',
            type: top === 'gainers' ? 'desc' : 'asc',
          },
    [top, sort, sortChanged]
  )

  const sortedData = useMemo(() => {
    return orderBy(
      data,
      [
        d =>
          currentSort.field === 'name' &&
          typeof d[currentSort.field] === 'string'
            ? d[currentSort.field].toLocaleLowerCase()
            : d[currentSort.field],
      ],
      currentSort.type
    )
  }, [data, currentSort])

  const slicedData = useMemo(
    () =>
      viewAll
        ? sortedData
        : sortedData.slice(
            currentPage * rowsOnPage,
            (currentPage + 1) * rowsOnPage
          ),
    [sortedData, currentPage, rowsOnPage, viewAll]
  )

  const dataWithPnL = useMemo(() => {
    let totalPnL = 0
    let totalHoldings = 0

    const data = slicedData.map(item => {
      let coinPnl

      if (pnl && pnl[item.slug]) {
        coinPnl = pnl[item.slug]

        if (coinPnl.amount && coinPnl.tradePrice && isNumber(item.price)) {
          const { pnlAbs } = calculatePnL(
            coinPnl.amount,
            coinPnl.tradePrice,
            item.price
          )

          totalPnL += pnlAbs
          totalHoldings += coinPnl.tradePrice * Math.abs(coinPnl.amount)
        }
      }

      return { ...item, pnl: coinPnl }
    })

    const totalPnLPercent =
      totalHoldings > 0 ? (totalPnL * 100) / totalHoldings : 0

    return { data, totalPnL, totalPnLPercent }
  }, [slicedData, pnl])

  return {
    ...dataWithPnL,
    sort: currentSort,
    rowsOnPage,
  }
}

export const usePagination = ({ data, top, viewAll, setViewAll }) => {
  const [page, setPage] = useState(0)
  const rowsOnPage = ROWS_ON_PAGE

  const currentPage = useMemo(
    () => (top === 'all' ? page : page > 0 ? 1 : 0),
    [top, page]
  )

  const toggleViewAll = useCallback(() => {
    setPage(0)
    setViewAll(!viewAll)
    combinedAnalytics('Button', 'Button', 'View', viewAll ? '100' : 'All')

    if (viewAll) {
      // TODO scroll top
    }
  }, [setViewAll, viewAll])

  const changePage = useCallback(
    change => () => {
      const nextPage = page + change
      setPage(nextPage)

      combinedAnalytics(
        'Button',
        'Button',
        'Table Pages',
        change > 0 ? `Next_${nextPage}00` : `Prev_${nextPage}00`
      )

      // TODO scroll top
    },
    [page]
  )

  const pageTotal = useMemo(
    () => (Array.isArray(data) ? Math.ceil(data.length / rowsOnPage) : 0),
    [data, rowsOnPage]
  )

  return {
    page: currentPage,
    pageTotal,
    viewAll,
    rowsOnPage,
    changePage,
    toggleViewAll,
  }
}

export const useDiffData = ({ data, currency = 'USD', isMobile, entity }) => {
  const prevData = useRef([])
  const prevCurrency = useRef()

  const getValues = useMemo(() => getValuesBuilder(entity), [entity])

  return useMemo(() => {
    const lastObj = prevData.current.reduce(
      (acc, item) => ({ ...acc, [item.slug]: item }),
      {}
    )

    const result = data.map(item => {
      const lastItem = lastObj[item.slug]

      const {
        change,
        marketCap,
        price,
        volume,
        color,
        supply,
        volumeReported,
        launched,
        volumeAdjusted,
        twitter,
        reddit,
        trustPilotRatingPercent,
        visits,
        numSupply,
        numMarketCap,
        numVolume,
        numChange,
        pnl,
      } = getValues(item, currency, isMobile)

      const mainCondition = currency === prevCurrency.current && lastItem

      let animation

      if (mainCondition && lastItem.price !== price) {
        animation =
          lastItem.numPrice > item.price ? 'tableViewRed' : 'tableViewGreen'
      }

      let rerenderPnl = false
      if ((lastItem && lastItem.pnl) || pnl) {
        rerenderPnl = true
      }

      return {
        ...item,
        rerenderPnl,
        animation,
        change,
        marketCap,
        price,
        volume,
        volumeReported,
        color,
        supply,
        numPrice: item.price,
        numChange,
        numSupply,
        numMarketCap,
        numVolume,
        launched,
        volumeAdjusted,
        twitter,
        reddit,
        trustPilotRatingPercent,
        visits,
      }
    })

    prevData.current = result
    prevCurrency.current = currency

    return result
  }, [data, currency, getValues, isMobile])
}
