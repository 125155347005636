export const EXCHANGES_TABLE_TYPES = {
  OVERVIEW: 'OVERVIEW',
  SOCIALS: 'SOCIALS',
}

export const COINS_TABLE_TYPES = {
  DEFAULT: 'DEFAULT',
  WATCHLIST: 'WATCHLIST',
}

export const NFT_TABLE_TYPES = {
  DEFAULT: 'DEFAULT',
}

export const ROWS_ON_PAGE = 100
