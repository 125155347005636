import React, { memo } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'

export const SortArrow = memo(({ order, position, isMobile }) => (
  <div
    className={clsx(
      styles.sortArrowContainer,
      order === 'asc' ? styles.rotate : null,
      position === 'right' ? styles.positionRight : styles.positionLeft
    )}
  >
    <svg
      width={isMobile ? 12 : 16}
      height={isMobile ? 12 : 16}
      viewBox='0 0 16 16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M2.66602 8L3.60602 8.94L7.33268 5.22L7.33268 13.3333L8.66602 13.3333L8.66601 5.22L12.3927 8.94L13.3327 8L7.99935 2.66667L2.66602 8Z' />
    </svg>
  </div>
))
