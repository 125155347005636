import React from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import { ENTITY } from 'src/consts'
import { intl } from 'src/i18n'

const Pagination = ({
  count,
  needAdditionalInfo = false,
  onAll,
  onNext,
  onPrev,
  page,
  pageTotal,
  toTopScroll = undefined,
  viewAll,
  position = 'left',
  entity = '',
}) => {
  const hereOnNext = () => {
    onNext()
    toTopScroll && toTopScroll()
  }

  const hereOnPrev = () => {
    onPrev()
    toTopScroll && toTopScroll()
  }

  const hereOnAll = () => {
    onAll()
    toTopScroll && toTopScroll()
  }

  return (
    <div className={clsx(styles.container, styles[position])}>
      {needAdditionalInfo && (
        <div className={styles.additionalInfo}>
          *Average month traffic by Similar Web
        </div>
      )}
      <div className={styles.rightContainer}>
        {page > 0 && !viewAll && page < pageTotal && (
          <div>
            <span className={styles.button} onClick={hereOnPrev}>
              <svg
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                className={styles.buttonArrowLeft}
              >
                <path
                  d='M8.00065 13.3333L8.94065 12.3933L5.22065 8.66667H13.334V7.33333L5.22065 7.33333L8.94065 3.60667L8.00065 2.66667L2.66732 8L8.00065 13.3333Z'
                  fill='currentColor'
                />
              </svg>
              {intl.t('components.pagination.prev', { count })}
            </span>
          </div>
        )}

        {page < pageTotal - 1 && !viewAll && pageTotal > 1 && (
          <div>
            <span className={styles.button} onClick={hereOnNext}>
              {intl.t('components.pagination.next', { count })}
              <svg
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                className={styles.buttonArrowRight}
              >
                <path
                  d='M7.99935 2.66667L7.05935 3.60667L10.7793 7.33333H2.66602V8.66667H10.7793L7.05935 12.3933L7.99935 13.3333L13.3327 8L7.99935 2.66667Z'
                  fill='currentColor'
                />
              </svg>
            </span>
          </div>
        )}

        {pageTotal > 1 && entity !== ENTITY.NFT && (
          <span
            className={clsx(styles.button, styles.buttonAll)}
            onClick={hereOnAll}
          >
            {viewAll
              ? intl.t('components.pagination.view', { count })
              : intl.t('components.pagination.viewAll')}
          </span>
        )}
      </div>
    </div>
  )
}

export default Pagination
