import { useRef, useState } from 'react'
import styles from './styles.module.scss'

export const EditableCell = ({
  value,
  onChange,
  regexp,
  allowZerro,
  type,
  id,
  name,
  placeholder,
  title,
}) => {
  const inputRef = useRef()
  const [currValue, setCurrValue] = useState(value)

  const changeHandler = e => {
    if (e.target.value === '') {
      setCurrValue(e.target.value)
      return
    }

    const match = e.target.value.match(
      /^\-?((?:0|[1-9][0-9]*)(?:\.|(\.[0-9]{1,}))?)?$/
    )

    if (match && match[0]) {
      setCurrValue(e.target.value)
    }
  }

  const keypressHandler = event => {
    if (event.key === 'Enter') {
      saveValue()
      inputRef.current.blur()
    }
  }

  const saveValue = () => {
    if (currValue === '') {
      onChange(currValue)
      return
    }

    const match = currValue.match(regexp)

    if (match && match[0]) {
      const formatted = currValue.replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1')
      if (!allowZerro && parseFloat(formatted) === 0) {
        setCurrValue(value)
        return
      }
      onChange(formatted)
      setCurrValue(formatted)
    } else {
      setCurrValue(value)
    }
  }

  return (
    <input
      className={styles.editableAmount}
      type={type}
      id={id}
      name={name}
      value={currValue}
      onChange={event => changeHandler(event)}
      placeholder={placeholder}
      title={title}
      onKeyPress={event => keypressHandler(event)}
      onBlur={saveValue}
      ref={inputRef}
      autoComplete='off'
    />
  )
}

export default EditableCell
