import React, { useRef, useState, useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import Image from 'next/image'
import Pagination from 'src/components/Pagination'
import { ENTITY, EXCHANGES_TABLE_TYPES } from 'src/consts'
import useWidth from 'src/hooks/useWidth'
import { getActiveWatchlist } from 'src/redux/selectors'
import { getDirectionByChange } from 'src/utils/common'
import { switchMarketCap } from 'src/utils/currency'
import { formatPnLAbs, formatPnLPercent } from 'src/utils/number/pnl'
import { TableHeader, TableBody } from './components'
import { getTableViewConfig } from './config'
import { getDefaultTableViewTypeByEntity } from './helpers'
import { useData, usePagination, useDiffData } from './hooks'
import styles from './styles.module.scss'

export const TableView = ({
  category = 'coin',
  tableType = 'DEFAULT',
  currency,
  entity,
  activePeriod,
  entitiesData,
  globalData = undefined,
  isWatchList = false,
  onPageChange,
  onHeaderSlot = undefined,
}) => {
  const containerRef = useRef(null)
  const tableRef = useRef(null)
  const { isMobile, getWidth, getWidthWindow } = useWidth()
  const [table, setTable] = useState(
    getDefaultTableViewTypeByEntity(entity, tableType)
  )
  const tableConfig = getTableViewConfig(
    category,
    table,
    currency,
    activePeriod,
    isMobile
  )
  const [tableHeaderScroll, setTableHeaderScroll] = useState(false)
  const activeWatchList = useSelector(getActiveWatchlist)
  const [scrollTable, setScrollTable] = useState(0)

  const [viewAll, setViewAll] = useState(false)
  const [sort, setSort] = useState({
    field: tableConfig.defaultSortField,
    type: 'desc',
  })

  const dataPagination = usePagination({
    data: entitiesData,
    top: 'all',
    viewAll,
    setViewAll,
  })

  const data = useData({
    data: entitiesData,
    top: 'all',
    page: dataPagination.page,
    sort: sort,
    viewAll,
    pnl: activeWatchList?.pnl,
  })

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 225) setTableHeaderScroll(true)
      else setTableHeaderScroll(false)
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const formattedData = useDiffData({
    data: data.data,
    currency: currency,
    isMobile: isMobile,
    entity,
  })

  const tableWidth = useMemo(() => {
    if (!isMobile && getWidthWindow < 1660) {
      return 1000
    }
    return isMobile ? getWidth * tableConfig.mobileWidth : 1280
  }, [isMobile, tableConfig, getWidth, getWidthWindow])

  const colGroup = useMemo(
    () => (
      <colgroup>
        {tableConfig.data.map(({ colWidth }, index) => (
          <col
            key={index}
            style={{ width: `${colWidth}%`, minWidth: `${colWidth}%` }}
          />
        ))}
      </colgroup>
    ),
    [tableConfig.data]
  )

  const handleScrollTop = () => {
    window.scrollTo(0, containerRef.current.scrollTop)
    onPageChange()
  }

  const isNeedAdditionalInfo = useMemo(
    () =>
      entity === ENTITY.EXCHANGE && table === EXCHANGES_TABLE_TYPES.OVERVIEW,
    [entity, table]
  )

  const handleScrollLeft = () => {
    setScrollTable(tableRef.current.scrollLeft)
  }

  return (
    <div className={styles.tableViewContainer} ref={containerRef}>
      {onHeaderSlot}
      <h1 className={styles.header}>
        {isWatchList
          ? activeWatchList.name
          : tableConfig?.textHeader.replace(
              '%QUANTITY%',
              dataPagination.page === 0 && formattedData.length === 100
                ? '100 '
                : ''
            )}
      </h1>
      {!!globalData && (
        <h2 className={styles.subHeader}>
          Total Market Cap: {switchMarketCap(currency, globalData)}
        </h2>
      )}
      <div className={styles.adLogo}>
        <a
          href='https://www.gate.io/signup/VLFAVAGNBA?ref_type=103'
          rel='noreferrer nofollow noopener'
          target='_blank'
        >
          <Image
            src='/assets/gate-logo.webp'
            width={120}
            height={24}
            alt='gate'
          />
        </a>
        <a
          href='https://www.mexc.com/register?inviteCode=mexc-coin360head'
          rel='noreferrer nofollow noopener'
          target='_blank'
        >
          <Image
            src='/assets/mexc-logo.svg'
            width={120}
            height={120}
            alt='mexc'
          />
        </a>
      </div>
      <div
        className={clsx(styles.controlBlock, {
          [styles.column]: entity === ENTITY.EXCHANGE,
        })}
        style={{ width: tableWidth }}
      >
        {!isWatchList && (
          <div className={styles.row}>
            {entity === ENTITY.EXCHANGE && (
              <div className={styles.tableTypeSwitchContainer}>
                <div
                  onClick={() =>
                    setTable(
                      getDefaultTableViewTypeByEntity(
                        entity,
                        EXCHANGES_TABLE_TYPES.OVERVIEW
                      )
                    )
                  }
                  className={clsx(styles.tableTypeSwitch, {
                    [styles.active]: table === EXCHANGES_TABLE_TYPES.OVERVIEW,
                  })}
                >
                  Overview
                </div>
                <div
                  onClick={() =>
                    setTable(
                      getDefaultTableViewTypeByEntity(
                        entity,
                        EXCHANGES_TABLE_TYPES.SOCIALS
                      )
                    )
                  }
                  className={clsx(styles.tableTypeSwitch, {
                    [styles.active]: table === EXCHANGES_TABLE_TYPES.SOCIALS,
                  })}
                >
                  Social
                </div>
              </div>
            )}

            <Pagination
              count='100'
              viewAll={dataPagination.viewAll}
              page={dataPagination.page}
              pageTotal={dataPagination.pageTotal}
              onNext={dataPagination.changePage(1)}
              onPrev={dataPagination.changePage(-1)}
              onAll={dataPagination.toggleViewAll}
              needAdditionalInfo={undefined}
              toTopScroll={handleScrollTop}
              entity={entity}
            />
          </div>
        )}
        <div
          className={clsx(styles.row, {
            [styles.watchList]: isWatchList,
          })}
        >
          {isWatchList && (
            <>
              {!isMobile && (
                <div className={styles.watchlistTotals}>
                  <span className={styles.watchlistTotalPrefix}>
                    Total P/L:
                  </span>
                  <span className={styles.watchlistTotalAmount}>
                    {' '}
                    ${formatPnLAbs(data.totalPnL)}{' '}
                  </span>
                  <span
                    className={clsx({
                      [styles[
                        getDirectionByChange(data.totalPnLPercent)
                      ]]: true,
                    })}
                  >
                    &nbsp;{formatPnLPercent(data.totalPnLPercent)}
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div className={styles.tableWrapper}>
        <div
          ref={tableRef}
          className={styles.tableContainer}
          onScroll={() => handleScrollLeft()}
        >
          <table className={styles.table} style={{ width: tableWidth }}>
            {colGroup}
            <TableHeader
              columns={tableConfig.data}
              isMobile={isMobile}
              isFullWidth={tableWidth}
              setSort={setSort}
              sort={sort}
              tableHeaderScroll={tableHeaderScroll}
              watchListPage={!isMobile && isWatchList}
              nftPage={!isMobile && entity === ENTITY.NFT}
              scrollTable={scrollTable}
              tableWidth={tableWidth} // pass tableWidth as a prop
            />
            <TableBody
              data={formattedData}
              columns={tableConfig.data}
              entity={entity}
              indexChange={
                viewAll ? 0 : dataPagination.page * dataPagination.rowsOnPage
              }
              viewAll={viewAll}
              currency={currency}
            />
          </table>
        </div>
      </div>
      <div
        className={clsx(styles.controlBlock, {
          [styles.column]: entity === ENTITY.EXCHANGE,
        })}
        style={{ width: tableWidth }}
      >
        <div
          className={clsx(styles.row, {
            [styles.watchList]: isWatchList,
          })}
        >
          <div className={styles.paginationContainerBottom}>
            <Pagination
              count='100'
              viewAll={dataPagination.viewAll}
              page={dataPagination.page}
              pageTotal={dataPagination.pageTotal}
              onNext={dataPagination.changePage(1)}
              onPrev={dataPagination.changePage(-1)}
              onAll={dataPagination.toggleViewAll}
              needAdditionalInfo={isNeedAdditionalInfo}
              toTopScroll={handleScrollTop}
              position={'right'}
              entity={entity}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
