import { isNumber, formatChangeValue } from './number'
import { formatCurrency2String, wrapCurrency } from './currency'

// TODO: copy of helper from table view - move to helpers.
const MILLION = 1000 * 1000
const BILLION = 1000 * 1000 * 1000

const formatValueWithCurrency = (value, currency, isInt, isMobile) => {
  const result = Number(value)
  if (!isMobile || result < MILLION) {
    return result
      ? wrapCurrency(formatCurrency2String(result, isInt, '0,0.00'), currency)
      : '—'
  }

  const del = value > BILLION ? BILLION : MILLION
  const order = value > BILLION ? 'B' : 'M'
  return wrapCurrency((value / del).toFixed(2) + order, currency)
}

export const formatPnLAbs = value => {
  if (!isNumber(value)) {
    return '-'
  }
  if (value === 0) {
    return '0'
  }
  return formatValueWithCurrency(value)
}

export const formatPnLPercent = value => {
  if (!isNumber(value)) {
    return '-'
  }
  if (value === 0) {
    return '0%'
  }
  return `${formatChangeValue(value)}%`
}

export const calculatePnL = (amount, tradePrice, price) => {
  const amountVal = parseFloat(amount)
  const tradePriceVal = parseFloat(tradePrice)

  const priceVal =
    typeof price === 'string'
      ? parseFloat(price.replace(/,/g, ''))
      : typeof price === 'number'
      ? price
      : 0

  const pnlAbs = (priceVal - tradePriceVal) * amountVal
  const pnlPercent =
    amountVal > 0
      ? ((priceVal - tradePriceVal) * 100) / tradePriceVal
      : ((tradePriceVal - priceVal) * 100) / tradePriceVal
  return { pnlAbs, pnlPercent }
}
