import { useSelector } from 'react-redux'
import {
  getTableViewData,
  getTableViewDataExchanges,
} from 'src/redux/selectors'
import { ENTITY } from 'src/consts'

export const useConnectedState = entity => {
  const data = useSelector(
    entity === ENTITY.COIN ? getTableViewData : getTableViewDataExchanges
  )

  return {
    data,
  }
}

export const useConnectedStateTop10List = entity => {
  const data = useSelector(
    entity === ENTITY.COIN ? getTableViewData : getTableViewDataExchanges
  ).slice(0, 10)

  return {
    data,
  }
}
