import React, { useRef, useState, useEffect } from 'react'
import Link from 'next/link'
import styles from './styles.module.scss'
import clsx from 'clsx'
import Image from 'next/image'
import dynamic from 'next/dynamic'
import useWidth from 'src/hooks/useWidth'
import { Icon } from 'src/components/Icon'
import { Rating } from 'src/components/Rating'
import { EditableCell } from '../EditableCell'
import {
  formatCurrency2String,
  switchfloorPriceCurrency,
} from 'src/utils/number/currency'

import {
  formatPnLAbs,
  formatPnLPercent,
  calculatePnL,
} from 'src/utils/number/pnl'
import { getDirectionByChange } from 'src/utils/common'
import { ENTITY } from 'src/consts'
import { copyToClipboard } from 'src/utils/copyToClipboard'
import toast from 'react-hot-toast'
import NotOkIcon from '../../../../../public/assets/icons/icon-notok.svg'
import BigNumber from 'bignumber.js'

BigNumber.config({ DECIMAL_PLACES: 16, EXPONENTIAL_AT: 1e9 })

// Render on client side
const LineChartPicture = dynamic(
  () => import('src/components/LineChartPicture'),
  { ssr: false }
)

export const TableRow = ({
  item,
  columns,
  entity,
  setWatchListItemPnL,
  data,
  deleteWatchListItem,
  currency,
}) => {
  const { isMobile } = useWidth()
  const refFirstColumn = useRef(null)
  const [widthSecondColumn, setWidthSecondColumn] = useState(null)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen)

  useEffect(() => {
    setWidthSecondColumn(refFirstColumn.current?.offsetWidth)
  }, [refFirstColumn.current?.offsetWidth])

  const handleAmountChange = value => {
    const { slug, pnl: { tradePrice } = {} } = data
    setWatchListItemPnL(slug, value, tradePrice)
  }

  const handleTradePriceChange = value => {
    const { slug, pnl: { amount } = {} } = data
    setWatchListItemPnL(slug, amount, value)
  }

  const calcPnL = () => {
    const { pnl: { amount, tradePrice } = {}, price } = data
    if (!amount || !tradePrice || !price) {
      return <div className={styles.pnLEmpty}>-</div>
    }

    const { pnlAbs, pnlPercent } = calculatePnL(amount, tradePrice, price)

    const pnlPercentDirection = getDirectionByChange(pnlPercent)

    const pnlAbsWithFormat = formatPnLAbs(pnlAbs)
    const pnlPercentWithFormat = formatPnLPercent(pnlPercent)

    return (
      <div className={styles.pnL}>
        <span className={styles.pnLAbs} title={pnlAbsWithFormat}>
          {pnlAbsWithFormat}
        </span>
        <span
          className={clsx(styles.pnLPercent, {
            [styles[pnlPercentDirection]]: true,
          })}
          title={pnlPercentWithFormat}
        >
          {pnlPercentWithFormat}
        </span>
      </div>
    )
  }

  const removeFromWatchList = () => {
    const { symbol } = data
    deleteWatchListItem(symbol)
  }

  const renderAnimation = animation => {
    return animation === 'tableViewGreen'
      ? styles.tableViewGreen
      : styles.tableViewRed
  }

  const loadStyles = className => {
    return clsx(
      className.map(item => {
        switch (item) {
          case 'leftCell':
            return styles.leftCell
          case 'rightCell':
            return styles.rightCell
          case 'idCell':
            return styles.idCell
          case 'centerCell':
            return styles.centerCell
          case 'updateCell':
            return styles.updateCell
          default:
            return
        }
      })
    )
  }

  const handleCopy = text => {
    try {
      const success = copyToClipboard(text)

      if (success) {
        toast('Adress has been successfully copied!')
      }
    } catch (e) {
      toast('Failed to copy the link!', {
        icon: <NotOkIcon height='20px' width='20px' />,
      })
    }
  }

  const recudeAdress = address => {
    return `${address.substring(0, isMobile ? 8 : 15)}...`
  }
  const [srcImage, setSrcImage] = useState(item.imgUrl)

  useEffect(() => {
    if (entity === ENTITY.NFT) setSrcImage(item.imgUrl)
  }, [entity, item])

  const renderCell = type => {
    if (entity === ENTITY.NFT) {
      const {
        name,
        address,
        numberOfOwners,
        floorPriceBlockchain,
        numberOfItems,
        marketCap,
        floorPrice,
        animation,
        price,
        change,
        volume,
        blockchain,
      } = item

      const formatFloorPrice = switchfloorPriceCurrency(currency, {
        floorPrice: floorPrice,
      })

      switch (type) {
        case 'price':
          return (
            <span
              className={clsx(
                styles.tableUpdateRow,
                animation ? renderAnimation(animation) : null
              )}
            >
              {price}
            </span>
          )
        case 'change':
          return (
            <span
              className={clsx(
                styles.tableUpdateRow,
                animation ? renderAnimation(animation) : null
              )}
            >
              {change}
            </span>
          )
        case 'floorPrice':
          return (
            <div className={styles.flooPriceContainer}>
              <span className={clsx(styles.nftName, styles.floorPriceName)}>
                <div
                  className={
                    blockchain.toLowerCase() !== 'ethereum'
                      ? styles.iconFloorPrice
                      : styles.iconFloorPriceEth
                  }
                >
                  <Image
                    src={`/assets/symbols/${blockchain.toLowerCase()}.svg`}
                    width='20'
                    height='20'
                    alt={blockchain}
                  />
                </div>
                {BigNumber(floorPriceBlockchain).toPrecision()}
              </span>
              <span className={styles.nftAddress}>{formatFloorPrice}</span>
            </div>
          )
        case 'marketCap':
          return <span className={styles.tableUpdateRow}>{marketCap}</span>
        case 'numberOfItems':
          return (
            <span className={styles.tableUpdateRow}>
              {formatCurrency2String(numberOfItems)}
            </span>
          )
        case 'numberOfOwners':
          return (
            <span className={styles.tableUpdateRow}>
              {formatCurrency2String(numberOfOwners)}
            </span>
          )
        case 'volume':
          return <span className={styles.tableUpdateRow}>{volume}</span>

        case 'name':
          return (
            <div className={styles.nftCollectionContainer}>
              <Link href={`/nft/${address}`}>
                <a>
                  <div className={styles.nftImage}>
                    <Image
                      alt={name}
                      className={styles.nftImg}
                      src={srcImage}
                      width={42}
                      height={42}
                      onError={() => setSrcImage('/assets/nft-placeholder.svg')}
                    />
                  </div>
                </a>
              </Link>
              <div className={styles.nftNameContainer}>
                <Link href={`/nft/${address}`}>
                  <a className={styles.nftUrl}>
                    <p className={styles.nftName}>{name}</p>
                  </a>
                </Link>
                <span
                  className={styles.nftAddress}
                  onClick={() => handleCopy(address)}
                >
                  {recudeAdress(address)}
                  <Image
                    src='/assets/icons/icon-copy.svg'
                    width='12'
                    height='12'
                    alt='copy icon'
                  />
                </span>
              </div>
            </div>
          )
        default:
          return <span className={styles.tableUpdateRow}>{item[type]}</span>
      }
    } else {
      const {
        name,
        coreType,
        symbol,
        slug,
        trustPilotTotal,
        trustPilotRatingPercent,
        animation,
        price,
        change,
        pnl: { amount, tradePrice } = {},
      } = item
      const entityPathName = slug
      switch (type) {
        case 'exchangeName':
          return (
            <div className={styles.symbolContainer}>
              <Link href={`/${entity}/${entityPathName}`}>
                <a>
                  <div className={styles.iconExchanges}>
                    <Icon
                      data={{ name, slug, symbol }}
                      size={isMobile ? '16x16' : '32x32'}
                      entity={entity}
                    />
                  </div>
                  <div className={styles.exchangeNameContainer}>
                    <span style={{ marginLeft: 5 }}>{symbol}</span>
                    {coreType && (
                      <span
                        className={clsx(styles.symbolTypeContainer, {
                          [styles.decentralized]: coreType === 'Decentralized',
                        })}
                      >
                        {coreType}
                      </span>
                    )}
                  </div>
                </a>
              </Link>
            </div>
          )
        case 'symbol':
          return (
            !isMobile && (
              <div className={styles.symbolContainer}>
                <Link href={`/${entity}/${entityPathName}`}>
                  <a>
                    <Icon
                      data={{ name, slug, symbol }}
                      size='16x16'
                      entity={entity}
                    />
                    <span style={{ marginLeft: 5 }}>{symbol}</span>
                  </a>
                </Link>
              </div>
            )
          )
        case 'name':
          if (!isMobile)
            return (
              <Link href={`/${entity}/${entityPathName}`}>
                <a>
                  <span className={styles.name}>{name}</span>
                </a>
              </Link>
            )

          return (
            <>
              <div className={styles.symbolContainerMobile}>
                <Link href={`/${entity}/${entityPathName}`}>
                  <a>
                    <div>
                      <Icon
                        data={{ name, slug, symbol }}
                        size='16x16'
                        entity={entity}
                      />
                      <span style={{ marginLeft: 5 }}>{symbol}</span>
                    </div>
                    <span className={styles.mobileNameCell}>{name}</span>
                  </a>
                </Link>
              </div>
            </>
          )
        case 'buy': {
          return (
            <div className={styles.buyDropdownContainer}>
              <button
                onClick={toggleDropdown}
                className={styles.buyDropdownButton}
              >
                Buy
              </button>
              {isDropdownOpen && (
                <ul className={styles.buyDropdownList}>
                  <li>
                    <Image
                      src='assets/mexc-logo-small.svg'
                      height={15}
                      width={15}
                      alt='mexc'
                    />
                    <a
                      href='https://www.mexc.com/register?inviteCode=mexc-coin360head'
                      target='_blank'
                      rel='noopener noreferrer nofollow'
                    >
                      Mexc
                    </a>
                  </li>
                  <li>
                    <Image
                      src='assets/gate-logo-small.svg'
                      height={15}
                      width={15}
                      alt='gate'
                    />
                    <a
                      href='https://www.gate.io/signup/VLFAVAGNBA?ref_type=103'
                      target='_blank'
                      rel='noopener noreferrer nofollow'
                    >
                      Gate
                    </a>
                  </li>
                  <li>
                    <Image
                      src='assets/binance-logo.svg'
                      height={15}
                      width={15}
                      alt='Binance'
                    />
                    <a
                      href='https://accounts.binance.com/register?ref=216897466'
                      target='_blank'
                      rel='noopener noreferrer nofollow'
                    >
                      Binance
                    </a>
                  </li>
                </ul>
              )}
            </div>
          )
        }

        case 'price':
          return (
            <span
              className={clsx(
                styles.tableUpdateRow,
                animation ? renderAnimation(animation) : null
              )}
            >
              {price}
            </span>
          )
        case 'change':
          return (
            <span
              className={clsx(
                styles.tableUpdateRow,
                animation ? renderAnimation(animation) : null
              )}
            >
              {change}
            </span>
          )
        case 'watchListName':
          return (
            <div className={styles.watchListNameContainer}>
              {!isMobile && (
                <div
                  className={styles.watchListNameStar}
                  onClick={removeFromWatchList}
                >
                  <Image
                    src='/assets/icons/icon-table-star.svg'
                    width='18px'
                    height='18px'
                    layout='fixed'
                    alt='Star icon'
                  />
                </div>
              )}
              <Link href={`/${entity}/${entityPathName}`}>
                <a className={styles.watchListNameLink}>
                  {!isMobile ? (
                    <>
                      <div className={styles.watchListIconContainer}>
                        <Icon
                          data={{ name, slug }}
                          timeout
                          size='16x16'
                          entity={entity}
                        />
                      </div>
                      <div className={styles.watchListNameText}>
                        <span className={styles.watchListName}>{name}</span>
                        <span className={styles.watchListNameAbbr}>
                          {symbol}
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={styles.nameContainerMobile}>
                        <div className={styles.watchListIconContainer}>
                          <Icon
                            data={{ name, slug }}
                            timeout
                            size='16x16'
                            entity={entity}
                          />
                        </div>
                        <span className={styles.watchListName}>{symbol}</span>
                      </div>

                      <div className={styles.watchListNameText}>
                        <span className={styles.watchListNameAbbr}>{name}</span>
                      </div>
                    </>
                  )}
                </a>
              </Link>
            </div>
          )
        case 'graph':
          return <LineChartPicture type='table' slug={slug} isMobile />
        case 'trustPilotScore':
          return (
            <div className={styles.ratingCell}>
              {trustPilotTotal} comments
              <Rating
                percent={trustPilotRatingPercent}
                width={92}
                height={18}
              />
            </div>
          )
        case 'amount':
          return (
            <EditableCell
              type='text'
              id='amount'
              name='amount'
              value={amount || ''}
              onChange={handleAmountChange}
              placeholder='-'
              title={amount}
              regexp={/^\-?(?:0|[1-9][0-9]*)(?:\.[0-9]{1,})?$/}
              allowZerro={false}
            />
          )
        case 'tradePrice':
          return (
            <EditableCell
              type='text'
              id='tradePrice'
              name='tradePrice'
              value={tradePrice || ''}
              onChange={handleTradePriceChange}
              placeholder='-'
              title={tradePrice}
              regexp={/^(?:0|[1-9][0-9]*)(?:\.[0-9]{1,})?$/}
              allowZerro={true}
            />
          )
        case 'pnl':
          return calcPnL()
        default:
          return <span className={styles.tableUpdateRow}>{item[type]}</span>
      }
    }
  }

  return (
    <tr className={styles.tableRow}>
      {columns.map(
        ({ dataKey, className, stickyColumn, left, position }, index) => {
          if (dataKey === 'symbol' && isMobile) return null

          let styleCell = {}
          if (dataKey === 'change') {
            styleCell = {
              ...styleCell,
              color: item.color,
            }
          }
          if (stickyColumn && isMobile) {
            styleCell = {
              ...styleCell,
              position: 'sticky',
              left: left !== undefined ? `${left}%` : `${widthSecondColumn}px`,
            }
          }
          return (
            <td
              ref={position === 'firstColumn' ? refFirstColumn : null}
              className={clsx(
                styles.tableCell,
                loadStyles(className),
                stickyColumn && isMobile && styles.mobileCell
              )}
              key={index}
              style={styleCell}
            >
              {renderCell(dataKey)}
            </td>
          )
        }
      )}
    </tr>
  )
}

export default TableRow
