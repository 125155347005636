import React, { useRef } from 'react'
import TableRow from '../TableRow'
import styles from './styles.module.scss'
import { setWatchListItemPnL, deleteWatchListItem } from 'src/redux/thunks'
import { useDispatch } from 'react-redux'

export const TableBody = ({ data, columns, entity, currency }) => {
  const bodyRef = useRef()
  const dispatch = useDispatch()

  return (
    <tbody ref={bodyRef} className={styles.tableBody}>
      {data.map(el => (
        <TableRow
          key={el.slug}
          item={el}
          columns={columns}
          entity={entity}
          data={el}
          setWatchListItemPnL={(slug, amount, tradePrice) =>
            dispatch(setWatchListItemPnL(slug, amount, tradePrice))
          }
          deleteWatchListItem={symbol => dispatch(deleteWatchListItem(symbol))}
          currency={currency}
        />
      ))}
    </tbody>
  )
}

export default TableBody
