import React, { useRef, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { SortArrow } from '../index'
import clsx from 'clsx'
import { combinedAnalytics } from 'src/utils/common'

export const TableHeader = ({
  columns,
  sort,
  setSort,
  isMobile,
  isFullWidth,
  tableHeaderScroll,
  watchListPage,
  nftPage,
  scrollTable,
  tableWidth, // receive tableWidth as a prop
}) => {
  const refFirstColumn = useRef(null)
  const [widthSecondColumn, setWidthSecondColumn] = useState(null)
  const tHeadRef = useRef(null)
  useEffect(() => {
    setWidthSecondColumn(refFirstColumn.current?.offsetWidth)
  }, [refFirstColumn.current?.offsetWidth])

  const clickOnSort = sortValue => {
    sort.field === sortValue
      ? sort.type === 'desc'
        ? setSort({
            ...sort,
            type: 'asc',
          })
        : setSort({
            ...sort,
            type: 'desc',
          })
      : setSort({
          field: sortValue,
          type: 'desc',
        })
    combinedAnalytics(
      'Filter',
      'Filter',
      'Interaction:table sort',
      `${sortValue ? sortValue : sort.field}_${
        sort.type === 'desc' ? 'Down' : 'Up'
      }`
    )
  }

  const loadStyles = className => {
    return clsx(
      className.map(item => {
        switch (item) {
          case 'leftCell':
            return styles.leftCell
          case 'rightCell':
            return styles.rightCell
          case 'idCell':
            return styles.idCell
          case 'centerCell':
            return styles.centerCell
          default:
            return
        }
      })
    )
  }

  useEffect(() => {
    tHeadRef.current.scrollLeft = scrollTable
  }, [scrollTable])

  return (
    <thead
      ref={tHeadRef}
      className={clsx(
        styles.tableHeader,
        tableHeaderScroll && styles.tableHeaderScroll,
        watchListPage && styles.watchlist,
        nftPage && styles.watchlist
      )}
    >
      <tr className={styles.tableHeaderRow} style={{ width: tableWidth }}>
        {columns.map(
          (
            {
              className,
              colWidth,
              headerText,
              sortValue,
              sortArrowPosition,
              dataKey,
              stickyColumn,
              left,
              position,
            },
            index
          ) => {
            let styleCell = {
              width: `${colWidth}%`,
              minWidth: `${colWidth}%`,
            }

            if (stickyColumn && isMobile) {
              styleCell = {
                ...styleCell,
                width: `${colWidth}%`,
                minWidth: `${colWidth}%`,
                position: 'sticky',
                left:
                  left !== undefined ? `${left}%` : `${widthSecondColumn}px`,
                zIndex: '75',
              }
            }

            if (dataKey === 'symbol' && isMobile) return null
            return (
              <th
                ref={position === 'firstColumn' ? refFirstColumn : null}
                key={index}
                className={clsx(
                  styles.tableHeaderCell,
                  stickyColumn && isMobile && styles.mobileCell
                )}
                style={styleCell}
                onClick={sortValue ? () => clickOnSort(sortValue) : null}
              >
                <div
                  className={clsx(
                    styles.headerCellWrapper,
                    loadStyles(className),
                    sortValue === sort.field ? styles.activeCell : null
                  )}
                >
                  {sortArrowPosition && sortArrowPosition === 'left' && (
                    <SortArrow
                      order={sort.type}
                      position={sortArrowPosition}
                      isMobile={isMobile}
                    />
                  )}
                  {headerText}
                  {sortArrowPosition && sortArrowPosition === 'right' && (
                    <SortArrow
                      order={sort.type}
                      position={sortArrowPosition}
                      isMobile={isMobile}
                    />
                  )}
                </div>
              </th>
            )
          }
        )}
      </tr>
    </thead>
  )
}

export default TableHeader
