import { BTCtoSAT, ENTITY } from 'src/consts'
import {
  formatValueWithCurrency,
  switchCurrency,
  switchVolume,
  switchVolumeReported,
} from 'src/utils/number/currency'
import { formatChangeValue } from 'src/utils/number/number'
import { formatDate } from 'src/utils/time/moment.helper'
import {
  COINS_TABLE_TYPES,
  EXCHANGES_TABLE_TYPES,
  NFT_TABLE_TYPES,
} from './constants'

const checkEmpty = (value, currency) =>
  typeof value === 'string' &&
  value.trim() !== (currency === 'USD' ? '$' : currency)
    ? value
    : '?'

const switchBtcSat = value =>
  value < 1e-8
    ? `${(value * BTCtoSAT).toFixed(4)} SAT`
    : formatValueWithCurrency(value, false, false)

export const getValuesBuilder = type => {
  let color = '#bcb2b1'

  return {
    coin: (item, currency, isMobile) => {
      const change = `${formatChangeValue(item.change, item.price)}%`
      const supply = formatValueWithCurrency(
        item.emission,
        false,
        true,
        isMobile
      )
      const volume = formatValueWithCurrency(item.volume, false, true, isMobile)
      const price =
        currency === 'BTC'
          ? switchBtcSat(item.price)
          : formatValueWithCurrency(item.price, false, false, isMobile)
      const marketCap = formatValueWithCurrency(
        item.marketCap,
        false,
        true,
        isMobile
      )

      if (Math.abs(item.change) > 0.01) {
        color = item.change > 0 ? '#84C47C' : '#DC7474'
      } else {
        color = '#bcb2b1'
      }

      return {
        change,
        color,
        supply,
        volume,
        numVolume: item.volume,
        numSupply: item.emission,
        numMarketCap: item.marketCap,
        numChange: item.change,
        price,
        marketCap,
      }
    },
    exchange: (item, currency, isMobile) => {
      const volumeReported = switchVolumeReported(currency, item)
      const change = `${formatChangeValue(item.changeReported, false)}%`
      const launched = item.launched ? formatDate(item.launched, 'yyyy') : '?'
      const volume = switchVolume(currency, item)
      const twitter = formatValueWithCurrency(
        item.twitter,
        false,
        true,
        isMobile
      )
      const reddit = formatValueWithCurrency(item.reddit, false, true, isMobile)
      const trustPilotRatingPercent = Math.round(
        (item.trustPilotScore / 10) * 100
      )
      const visits = formatValueWithCurrency(item.visits, false, true, isMobile)

      if (Math.abs(item.changeReported) > 0.01) {
        color = item.changeReported > 0 ? '#84C47C' : '#DC7474'
      }

      return {
        volumeReported: checkEmpty(volumeReported, currency),
        change,
        color,
        launched,
        volumeAdjusted: checkEmpty(volume, currency),
        twitter,
        reddit,
        trustPilotRatingPercent,
        visits,
      }
    },
    nft: (item, currency) => {
      const change = `${formatChangeValue(item.change, false)}%`
      const volume = switchVolume(currency, item)
      const marketCap = switchCurrency(currency, {
        price: item.marketCap,
      })

      if (Math.abs(item.change) > 0.01) {
        color = item.change > 0 ? '#84C47C' : '#DC7474'
      }

      return {
        ...item,
        change,
        volume,
        color,
        marketCap,
      }
    },
  }[type]
}

export const getDefaultTableViewTypeByEntity = (entity, tableType) => {
  switch (entity) {
    case ENTITY.COIN:
      return tableType ? tableType : COINS_TABLE_TYPES.DEFAULT
    case ENTITY.EXCHANGE:
      return tableType ? tableType : EXCHANGES_TABLE_TYPES.OVERVIEW
    case ENTITY.NFT:
      return NFT_TABLE_TYPES.DEFAULT
    default:
      return COINS_TABLE_TYPES.DEFAULT
  }
}
